import React from 'react'

export default function SanBruno() {
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>San Bruno</button>
            <div className='city-dropdown-content'>
                <a href='https://www.sanbruno.ca.gov/DocumentCenter/View/3012/Horizontal-and-Vertical-Control-Network-Monument-Map-PDF' target={'_blank'} rel='noreferrer'>Benchmarks</a>
            </div>
        </div>
    )
}

