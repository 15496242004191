import React from 'react'

export default function LagunaNigel() {
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>Laguna Nigel</button>
            <div className='city-dropdown-content'>
                <a href='https://www.arcgis.com/home/webmap/viewer.html?webmap=07be648173c444fe88e67efeab2486a6&extent=-117.7637,33.4886,-117.637,33.5573' target={'_blank'} rel='noreferrer'>Tract Map & CL Ties</a>
            </div>
        </div>
    )
}
