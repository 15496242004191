import React from 'react'

export default function Hemet() {
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>Hemet</button>
            <div className='city-dropdown-content'>
                <a href='https://www.hemetca.gov/385/Mapping' target={'_blank'} rel='noreferrer'>CL Ties & Benchmarks</a>
            </div>
        </div>
    )
}


